import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/campaign/:campaignId?",
    beforeEnter: AuthGuard.withCompleteOnboarding,
    meta: { title: "campaign.overview.meta", layout: "AuthenticatedLayout" },
    children: [
      {
        path: "",
        name: "CampaignProducts",
        component: () => import("@/modules/campaign/overview/views/CampaignProducts.vue"),
      },
      {
        path: "product/:productId?",
        name: "CampaignOverview",
        component: () => import("@/modules/campaign/overview/views/CampaignOverview.vue"),
      },
    ],
  },
];
