import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/products",
    beforeEnter: AuthGuard.withCompleteOnboarding,
    meta: { title: "meta.balance", layout: "AuthenticatedLayout" },
    children: [
      {
        path: "/products",
        name: "Products",
        component: () => import("@/modules/products/views/TheProducts.vue"),
      },
    ],
  },
];
