import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/",
    beforeEnter: AuthGuard.withCompleteOnboarding,
    meta: { title: "deals.meta", layout: "AuthenticatedLayout" },
    children: [
      {
        path: "product/:productId",
        name: "ProductOverview",
        component: () => import("@/modules/product/views/ProductOverview.vue"),
      },
    ],
  },
];
