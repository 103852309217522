import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/account",
    beforeEnter: AuthGuard.withCompleteOnboarding,
    meta: { title: "meta.profile", layout: "AuthenticatedLayout" },
    children: [
      {
        path: "payment-methods",
        name: "PaymentMethods",
        component: () => import("@/modules/profile/payment-methods/views/PaymentMethods.vue"),
      },
    ],
  },
];
