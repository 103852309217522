import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/account",
    beforeEnter: AuthGuard.withCompleteOnboarding,
    meta: { title: "meta.profile", layout: "AuthenticatedLayout" },
    children: [
      {
        path: "overview",
        name: "AccountOverview",
        component: () => import("@/modules/profile/information/views/AccountOverview.vue"),
      },
    ],
  },
];
