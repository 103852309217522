import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/content",
    beforeEnter: AuthGuard.withCompleteOnboarding,
    meta: { title: "content.meta", layout: "AuthenticatedLayout" },
    children: [
      {
        path: "",
        name: "ContentListing",
        component: () => import("@/modules/content/views/ContentListing.vue"),
      },
    ],
  },
];
