<script lang="ts" setup>
defineOptions({
  name: "TFIcon",
});
</script>

<template>
  <svg viewBox="0 0 500 479.34" xmlns="http://www.w3.org/2000/svg">
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          class="cls-1"
          d="M144.65,100.81q-23.13-7.55-33.4-32.2c-4.5-10.83-2.84-31.8,3.37-42.39,22.28-38,78.43-33.83,93.85,7,12.17,32.26-10.8,67.42-45.38,69.49C156.9,103.05,149.1,102.26,144.65,100.81Zm-30.6,233.3V188.88H0V121.16H106c58.32,0,106,.61,106,1.35,0,5.23-13.16,65.6-14.44,66.07-.88.32-1.61,3.95-1.61,8.06,0,5.56-48.54,254.51-54.34,278.69-.89,3.72-1.95,4-14.29,4H114.05Z"
        />
        <path
          class="cls-2"
          d="M194.22,298.69,230,119.6H365c115,0,135,.37,135,2.52,0,1.37-2.81,15.82-6.23,32.1s-6.24,30-6.24,30.46-42.32.85-94,.85H299.44l-8.73,44.11c-4.79,24.25-9.08,45.5-9.51,47.22-.74,2.94,4.05,3.12,82.27,3.12,45.7,0,83.1.6,83.13,1.33s-2.94,16-6.6,33.84l-6.66,32.51-165.63.93-11,54.35c-6.07,29.89-12,59-13.17,64.59l-2.16,10.25h-83Z"
        />
      </g>
    </g>
  </svg>
</template>

<style lang="scss" scoped>
.cls-1 {
  fill: #7ba9f0;
}

.cls-2 {
  fill: #fdb44d;
}
</style>
