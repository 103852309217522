import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/deals",
    beforeEnter: AuthGuard.withCompleteOnboarding,
    meta: { title: "meta.profile", layout: "AuthenticatedLayout" },
    children: [
      {
        path: "",
        name: "TheDeals",
        component: () => import("@/modules/deals/views/TheDeals.vue"),
      },
    ],
  },
];
