import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/modules/account/views/TheLogin.vue"),
    beforeEnter: AuthGuard.withoutToken,
    meta: {
      layout: "UnauthenticatedLayout",
    },
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import("@/modules/account/views/TheSignup.vue"),
    beforeEnter: AuthGuard.withoutToken,
    meta: {
      layout: "UnauthenticatedLayout",
    },
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: () => import("@/modules/account/views/TheForgotPassword.vue"),
    beforeEnter: AuthGuard.withoutToken,
    meta: {
      layout: "UnauthenticatedLayout",
    },
  },
  {
    path: "/new-password/:token",
    name: "NewPassword",
    component: () => import("@/modules/account/views/TheNewPassword.vue"),
    meta: {
      layout: "UnauthenticatedLayout",
    },
  },
  {
    path: "/verify",
    name: "Verify",
    component: () => import("@/modules/account/views/TheVerifyCode.vue"),
    beforeEnter: AuthGuard.withEmailUnverified,
    meta: {
      layout: "UnauthenticatedLayout",
    },
  },
  {
    path: "/password",
    name: "Password",
    component: () => import("@/modules/account/views/ThePassword.vue"),
    meta: {
      layout: "UnauthenticatedLayout",
    },
  },
  {
    path: "/about-you",
    name: "AboutYou",
    component: () => import("@/modules/account/views/TheAboutYou.vue"),
    beforeEnter: AuthGuard.withIncompleteOnboarding,
    meta: {
      layout: "UnauthenticatedLayout",
    },
  },
  {
    path: "/account/impersonate/:token",
    name: "Impersonation",
    component: () => import("@/modules/account/views/TheImpersonate.vue"),
    beforeEnter: AuthGuard.withImpersonation,
    meta: {
      layout: "UnauthenticatedLayout",
    },
  },
];
