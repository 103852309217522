import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/:notFound",
    beforeEnter: AuthGuard.withoutToken,
    name: "NotFound",
    component: () => import("@/modules/error/views/TheNotFound.vue"),
  },
  {
    path: "/:catchAll(.*)",
    beforeEnter: AuthGuard.withToken,
    name: "AuthenticatedNotFound",
    component: () => import("@/modules/error/views/TheAuthenticatedNotFound.vue"),
    meta: {
      layout: "AuthenticatedLayout",
    },
  },
];
